import React from "react";
import { Link } from "gatsby";

export default function JoinOurTeam() {
  return (
    <section className="container-fluid join-our-team-section bg-gray py-4">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-8 text-center text-md-left">
            <h1 className="mb-3 uppercase">Join our team</h1>
            <p>We always are looking for experienced designers and developers or level up your skills as an apprentice.</p>
          </div>
          <div className="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end mt-3 mt-md-0">
            <Link className="btn btn-outline-dark px-md-5 py-2" to="/hiring">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
