import React from "react";
import Img from "gatsby-image"

export default function Member({ member, className }) {
  return (
    <div className={className}>
      <div className="team-member d-flex justify-content-left align-items-center">
        <div className="member-image-container">
          <Img className="member-image" fixed={member.picture.childImageSharp.fixed} />
        </div>
        <div className="member-info-container ml-4">
          <span className="member-name">{member.name}</span>
          <span className="member-role">{member.role}</span>
        </div>
      </div>
    </div>
  );
}
