import React from "react";
import { StaticQuery, graphql } from "gatsby"
import Member from "./Member";

function Team({
  data: {
    allMembersYaml: { edges },
  },
}) {
  return (
    <section className="container-fluid bg-white py-5 team-section bg-white">
      <div className="container py-3">
        <div className="row d-flex justify-content-center team-experts">
          {edges.map(edge => (
            <Member
              key={edge.node.id}
              member={edge.node}
              className="col-12 col-md-6 col-lg-4 my-3"
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query MembersQuery {
        allMembersYaml {
          edges {
            node {
              id
              name
              role
              picture {
                childImageSharp {
                  fixed(width: 160, height: 160) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Team data={data} {...props} />}
  />
)
