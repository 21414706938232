import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Team from "../components/Team/Team";
import JoinOurTeam from "../components/JoinOurTeam/JoinOurTeam";
import WorkTogether from "../components/WorkTogether/WorkTogether";
import PageHeader from "../components/PageHeader/PageHeader";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

class TeamPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={`Our Team | ${config.siteTitle}`} />
        <SEO />
        <Header />
        <PageHeader
          title="Team"
          content="Toptive is an expert team of developers and consultants. "
        />
        <Team />
        <JoinOurTeam />
        <WorkTogether />
        <Footer />
      </Layout>
    );
  }
}

export default TeamPage;
